import React from "react";

import "./_vpt-view.scss";

const DEBUG_VERSION = "";//"onProduction-432-g970508c-200908-155841/index.html";
const BASE_MXSTUDIO_URL = "https://mxstudio.appdataroom.com/" + DEBUG_VERSION;

class VptView extends React.Component {
    constructor(props) {
        super(props);

        let url = BASE_MXSTUDIO_URL + "?f=" + this.props.vptId + "&event=1";

        //binding
        this.iframeLoaded = this.iframeLoaded.bind(this);

        //state
        this.state = {
            url: url
        }
    }

    iframeLoaded() {
        //console.log("loaded")
        //let user
    }

    render() {

        if (!this.props.vptId) return null;

        return (
            <div className="vpt-view">
                <iframe title="vpt-viewer-iframe" src={this.state.url} onLoad={this.iframeLoaded} />
            </div>
        );
    }
}

export default VptView