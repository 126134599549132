import React from "react";
import LeadCaptureService from "services/leadCaptureService";
import Zendesk from "./Zendesk";
import SnapEngage from "./SnapEngage";

class ChatWidget extends React.Component {
    constructor(props) {
        super(props);

        //binding
        this.captureUserAsync = this.captureUserAsync.bind(this);
        this.onWidgetOpen = this.onWidgetOpen.bind(this);

        this.state = {
            user: LeadCaptureService.getCapturedUser()
        }
    }

    getWidgetType() {
        return this.props.chatProvider ? this.props.chatProvider : "zendesk";
    }

    //Widget Event Handlers
    async onWidgetOpen() {
        if (!this.state.user) {
            this.widget.close();
            this.captureUserAsync().then(this.widget.open);
            return false;
        }
    }

    //Widget Methods
    captureUserAsync() {
        return LeadCaptureService.captureAsync().then((user) => {
            this.setState({ user: user })
            this.widget.updateUser(user);
        }).catch(() => {
            return Promise.reject();
        })
    }


    render() {
        let widgetProps = {
            chatProviderKey: this.props.chatProviderKey,
            user: this.state.user,
            onWidgetOpen: this.onWidgetOpen,
            registerWidget: (w) => { this.widget = w },
            captureUser: this.captureUserAsync,
        }

        //let type = "snapengage"
        let type = this.getWidgetType();

        switch (type) {
            case "zendesk":
                return <Zendesk {...widgetProps} />
            case "snapengage":
                return <SnapEngage {...widgetProps} />
            default:
                return null;
        }
    }
}

export default ChatWidget;