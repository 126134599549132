import React from "react";

class Page404 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: props.match.params.status
        }
    }

    render() {
        return (
            <div className="page-not-found">
                404 - Not Found - {this.state.status}
            </div>
        );
    }
}

export default Page404;