import React from "react";
import ReactDOM from "react-dom";

import "./_modal.scss";

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
    constructor(props) {
        super(props);

        //binding
        this.backdropClick = this.backdropClick.bind(this);
        this.close = this.close.bind(this);
    }

    close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    backdropClick() {
        if (!this.props.isSticky && !this.props.isRequired) {
            this.close();
        }
    }

    render() {

        let modalClassName = "modal animated fadeInUp " + this.props.className;

        return ReactDOM.createPortal(
            (
                <div className="modal-container">
                    <div className="backdrop" onClick={this.backdropClick}></div>
                    <div className={modalClassName}>
                        {this.props.children}

                        {!this.props.isRequired &&
                            <button className='btn-close fas fa-times' onClick={this.close}></button>
                        }
                    </div>
                </div>
            ),
            modalRoot
        );
    }
}

export default Modal;