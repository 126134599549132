import axios from "axios";

function Api() {

    const returnResponseData = function (response) {
        return response.data;
    }
    const createError = function (ex) {
        let error = { status: 0, message: "An unknown expection occured" };

        if (ex.response) {
            let data = ex.response;
            error.status = data.status;
        }

        //message
        switch (error.status) {
        }

        return Promise.reject(error);
    }

    //Public Generic 
    this.get = function (url) {
        return axios.get(url).then(returnResponseData).catch(createError);
    }
}

export default new Api();