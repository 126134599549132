import React from "react";
import "./_zendesk-widget.scss";

const ZENDESK_WIDGET_URL = "https://static.zdassets.com/ekr/snippet.js?key=";
const ZENDESK_KEY_STUB_AERIN = "53f61fef-a0d8-4e95-b082-b568917abc31";

class ZendeskWidget extends React.Component {
    constructor(props) {
        super(props);

        let key = props.chatProviderKey || ZENDESK_KEY_STUB_AERIN;
        console.log("Starting ZenDesk");

        //Register with parent.
        this.props.registerWidget(this);

        //bindings
        this.onZendeskLoaded = this.onZendeskLoaded.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        //inject the zendesk script
        var script = document.createElement("script");
        script.id = 'ze-snippet';
        script.src = ZENDESK_WIDGET_URL + key;
        script.onload = this.onZendeskLoaded;
        document.body.appendChild(script);
    }
    componentDidMount() {
        //create widget settings.
        window.zESettings = { webWidget: { offset: { horizontal: '60px' } } }
    }

    //Zendesk
    onZendeskLoaded() {
        window.zE('webWidget:on', 'open', this.props.onWidgetOpen);
        this.updateUser(this.props.user);
    }
    open() {
        console.log("zendesk manual open");
        window.zE("webWidget", "open");
    }
    close() {
        window.zE("webWidget", "close");
    }
    updateUser(user) {
        if (!user) return;
        //update zendesk - should this be a different service?
        window.zE('webWidget', 'prefill', {
            name: { value: user._FullName, readOnly: false },
            email: { value: user.Email, readOnly: false },
            phone: { value: user.Phone, readOnly: false }
        });

        window.zE('webWidget', 'identify', {
            name: user._FullName,
            email: user.Email,
            phone: user.Phone,
        });
    }

    render() {
        return null;
    }
}

export default ZendeskWidget;