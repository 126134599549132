import React from "react";
import Modal from "../Modal";

import captureForm from "capture-form";

import "./_lead-capture-modal.scss";

class LeadCaptureModal extends React.Component {
    constructor(props) {
        super(props);

        this.unmounted = false;
        this.close = this.close.bind(this);
    }
    componentDidMount() {
        captureForm(this.props.conventionId, this.props.leadGuid).then(result => {
            if (this.unmounted) return;
            if (this.props.onLeadCaptureSuccess) {
                this.props.onLeadCaptureSuccess(result);
            }
        }).catch(ex => {
            //TODO: display an error?
            if (this.unmounted) return;
            if (this.props.onLeadCaptureFail) {
                this.props.onLeadCaptureFail(ex);
            }
        }).finally(() => {
            this.props.onClose();
        });

        //TEMP HACK
        return;
        /*
        if (window.count === 1 && window.location.hostname === "localhost") {
            setTimeout(() => {
                console.log("return a fake lead capture user!")
                this.props.onLeadCaptureSuccess({
                    attributes: {
                        leads: [
                            {
                                leadAttributes: {
                                    Email: "awirth@appdataroom.com",
                                    "First Name": "Aaron",
                                    "Last Name": "Wirth (Client)",
                                    Company: "",
                                    Phone: "123-456-7890"
                                }
                            }
                        ]
                    }
                });
                this.props.onClose();
            }, 3000);
        }
        window.count = 1;
        */

    }
    componentWillUnmount() {
        this.unmounted = true;
    }

    close() {
        if (this.props.onLeadCaptureCanceled) {
            this.props.onLeadCaptureCanceled();
        }

        this.props.onClose();
    }

    render() {
        return (
            <Modal onClose={this.close} isRequired={this.props.isRequired} className="lead-capture-modal">
                <div className="modal-body" id="form-here-please"></div>
            </Modal>
        );
    }
}

export default LeadCaptureModal;