import React from "react";

class ModalPortalExtension extends React.Component {
    constructor(props) {
        super(props);

        this.show = this.show.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = { child: null }
    };

    componentDidMount() {
        this.props.onMount(this);
    }

    //External 
    show(child) {
        this.setState({ child });
    }
    onClose() {
        this.setState({ child: null });
    }

    //Render
    render() {
        if (!this.state.child) return null;
        let child = React.cloneElement(this.state.child, { onClose: this.onClose })
        return child;
    }
};

export default ModalPortalExtension