import React from "react";
import "./_snap-engage.scss";

const SNAP_ENGAGE_SRC = "https://storage.googleapis.com/code.snapengage.com/js/"
let SnapEngage;

class SnapEngageWidget extends React.Component {
    constructor(props) {
        super(props);

        let key = props.chatProviderKey
        console.log("Starting Snap Engage");

        //Register with parent.
        this.props.registerWidget(this);

        //bindings
        this.onSnapEngageLoaded = this.onSnapEngageLoaded.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        //inject the zendesk script
        var script = document.createElement("script");
        script.id = 'snap-snippet';
        script.type = "text/javascript";
        script.async = true;
        script.src = SNAP_ENGAGE_SRC + key + ".js";

        script.onload = this.onSnapEngageLoaded;
        document.body.appendChild(script);
    }

    //SnapEnage
    onSnapEngageLoaded() {
        SnapEngage = window.SnapEngage;
        SnapEngage.setCallback('Open', this.props.onWidgetOpen);
        this.updateUser(this.props.user);
    }

    //TODO: these are like interface methods. Each integration needs to have them and the parent can call them.
    open() {
        document.getElementById("designstudio").classList.remove("hidden");
        SnapEngage.startChat();
    }
    close() {
        if (!this.props.user) {
            document.getElementById("designstudio").classList.add("hidden");
        }

        setTimeout(() => { SnapEngage.endChat({ closeChatBox: true }); }, 100);
    }
    updateUser(user) {
        if (!user) return;

        SnapEngage.setUserEmail(user.Email);
        SnapEngage.setUserName(user._FullName);
        SnapEngage.setDescription("Virtual Booth Help Request");
    }

    //Render
    render() {
        return null;
    }
}

export default SnapEngageWidget;