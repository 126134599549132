import React from "react";

import VptView from "components/VptView";
import PoweredByLink from "components/PoweredByLink";
import ChatWidget from "widgets";

import LeadCaptureService from "services/leadCaptureService";
import VxService from "services/vxService";

import "./_main.scss";

//2482;

class Main extends React.Component {
    constructor(props) {
        super(props);

        this._conventionId = props.match.params.conventionId

        //Binding
        this.loadPage = this.loadPage.bind(this);

        //load the virtual experience data
        VxService.getExperienceData(this._conventionId).then(this.loadPage).catch(ex => {
            console.error("failed to load: ", ex);
        });

        //setup state
        this.state = {
            vptId: null,
        }
    }

    loadPage(vxData) {
        LeadCaptureService.init(this._conventionId, vxData);
        this.setState({
            vptId: vxData.vpt_id,
            chatProvider: vxData["chat_provider"],
            chatProviderKey: vxData["chat_provider_key"]
        });
    }

    //Render
    render() {
        if (!this.state.vptId) return null;

        return (
            <div className="page-main">
                <VptView vptId={this.state.vptId} />
                <ChatWidget chatProvider={this.state.chatProvider} chatProviderKey={this.state.chatProviderKey} />
                <PoweredByLink />
            </div>
        );
    }
}

export default Main;