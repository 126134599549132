import React from "react";
import LeadCaptureModal from "components/LeadCaptureModal";
import utilities from "helpers/utilities";
import { showModal } from "components/Modal";

const LEAD_CAPTURE_STORAGE_KEY = "4wytg9uiaoe";

function LeadCaptureService() {

    const PLACEHOLDER_GUID = utilities.generateGUID();

    //variables    
    let _conventionId;
    let _gate;
    let _isLocked;

    //private 
    const saveLead = function (lead) {
        /*
            account_id: 746
            leadAttributes: {Email: "asdfasdf", First Name: "", Last Name: "", Company: "", Phone: "", …}
            lead_guid: "AF2AD264-4D78-283D-001A-9F950F1BC857"
            starttime: "2020-8-27 16:24:34"
            tradeshow_id: "2482"
            user_id: 98152
        */

        //VptBridge.

        localStorage.setItem(LEAD_CAPTURE_STORAGE_KEY, JSON.stringify(lead));
        return lead;
    }


    //setters
    this.init = function (conventionId, vxData) {
        _conventionId = conventionId;

        //build the gate data
        _gate = vxData.gate;
        _gate.eventCount = 0;

        //check for entry gate. 
        if (_gate.entry === 1) {
            this.captureAsync(true);
        }
    }

    this.handleBlockingEventAsync = function (event) {
        let isCaptureRequired = false;
        _gate.eventCount++;

        switch (event) {
            case "openMedia":
            case "playMedia":
                isCaptureRequired = _gate.media === 1;
        }

        if (isCaptureRequired && _gate.eventCount > _gate.view_limit) {
            return this.captureAsync();
        }

        return Promise.resolve();
    }

    this.captureAsync = function (isLocked) {
        if (_isLocked) return Promise.reject();
        _isLocked = isLocked;

        let user = this.getCapturedUser();
        if (user) return Promise.resolve(user);

        //show the form
        return new Promise((resolve, reject) => {
            let onLeadCaptureSuccess = (leadData) => {
                let lead = leadData.attributes.leads[0];
                saveLead(lead);
                resolve(this.getCapturedUser());
            }

            let onLeadCaptureFail = (ex) => {
                console.error("lead failed", ex);
                reject(ex);
            }

            let onLeadCaptureCanceled = () => {
                console.log("canceled lead capture")
                reject();
            }

            showModal(
                <LeadCaptureModal
                    conventionId={_conventionId}
                    leadGuid={PLACEHOLDER_GUID}
                    isRequired={isLocked}
                    onLeadCaptureCanceled={onLeadCaptureCanceled}
                    onLeadCaptureSuccess={onLeadCaptureSuccess}
                    onLeadCaptureFail={onLeadCaptureFail} />
            );
        });
    }

    //getters
    this.getLead = function () {
        let lead = localStorage.getItem(LEAD_CAPTURE_STORAGE_KEY);
        if (lead) {
            return JSON.parse(lead);
        }
        return { lead_guid: PLACEHOLDER_GUID, is_placeholder: true };
    }

    this.getCapturedUser = function () {
        let lead = this.getLead();
        if (lead && lead.leadAttributes) {
            let user = lead.leadAttributes;
            user._FullName = user["First Name"] + " " + user["Last Name"];
            return user;
        }
        return null;
    }
}

export default new LeadCaptureService();