import api from "./api";

const VX_STUBB = {
    "convention_id": 2482,
    "vpt_id": "DDGVX7gs",
    "capture_user_id": 98152,
    "chat_provider_key": "chattingallthetime247",
    "gate": {
        "chat": 1,
        "entry": 1,
        "hotspot": 0,
        "media": 1,
        "view_limit": 1
    }
}

function VxService() {
    this.getExperienceData = function (conventionId) {
        //return Promise.resolve(VX_STUBB);
        let url = "https://api3.appdataroom.com/virtualexperience/" + conventionId;
        return api.get(url);
    }
}

export default new VxService();