import LeadCaptureService from "services/leadCaptureService";
const VALID_ORIGINS = ['https://mxstudio.appdataroom.com']

function Bridge() {

    //Window Message Recieved Handlers
    const respond = function (orignalEvent, responseData) {
        let message = JSON.parse(orignalEvent.data);
        message.response = responseData ?? null;

        //temp
        if (message.type) {
            delete message.type;
        }
        console.log("VTS Bridge Responding: ", JSON.stringify(message));
        orignalEvent.source.postMessage(JSON.stringify(message), "*");
    }
    const reject = function (e, result) {
        let recievedMsg = JSON.parse(e.data);
        let error = { id: recievedMsg.id, error: "Testing An Error Method" }
        e.source.postMessage(JSON.stringify(error), "*");
    }
    const parseMessage = function (e) {
        if (VALID_ORIGINS.indexOf(e.origin) > -1) {
            let msg = JSON.parse(e.data);
            let method = msg.methodName;
            switch (method) {
                case "openMedia":
                case "playMedia":
                    LeadCaptureService.handleBlockingEventAsync(method).then((user) => {
                        console.log(user);
                        respond(e, user)
                    }).catch(() => {
                        reject(e)
                    });
                    return;
                case "getLead":
                    let lead = LeadCaptureService.getLead();
                    return respond(e, lead);
                default:
                    respond(e);
                    return null;
            }
        }
    }

    //Window Send Message
    const send = function () {
        let iframeWindow = document.getElementById('iframe');
        console.log(iframeWindow);
    }

    //public
    this.sendLeadChanged = function () {
        console.log('hi!');
        //send();
    }

    this.listen = function () {
        window.addEventListener("DOMContentLoaded", () => {
            window.addEventListener("message", parseMessage, false);
        });

    }
}

export default new Bridge();