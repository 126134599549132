function Extensions() {

    //Javascript Extensions
    Object.defineProperty(String.prototype, 'hashCode', {
        value: function () {
            var hash = 0, i, chr;
            for (i = 0; i < this.length; i++) {
                chr = this.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            return hash;
        }
    });

}

export default new Extensions();