import React from "react";

import "./_powered_by_link.scss";

class PoweredByLink extends React.Component {
    render() {
        return (
            <div className="powered-by-link">
                <a href='https://www.gomodus.com/modus-sales-hub/virtual-experience' target="_blank">
                    <span>Powered by</span>
                    <img src="https://s3.amazonaws.com/resources.appdataroom.com/branding/modus-logo-no_tagline-30px.png" />
                    <div className="learn-more">Learn More</div>
                </a>
            </div>
        );
    }
}

export default PoweredByLink;