import React from 'react';
import ReactDOM from 'react-dom';
import Modal from "./_Base/Modal";
import ModalPortalExtension from "./_Base/ModalPortalExtension";

const MODAL_PORTAL_EXTENSION_CONTAINER_ID = "modal_portal_extension_placeholder";
let _portalExtension;

//SEMI HACK TO MAKE GLOBALLY LAUNCHABLE MODALS WORK (e.g. LeadCaptureModal)
let init = function () {
    let container = document.getElementById(MODAL_PORTAL_EXTENSION_CONTAINER_ID);

    if (!container) {
        container = document.createElement("div");
        container.id = MODAL_PORTAL_EXTENSION_CONTAINER_ID;
        document.body.appendChild(container);
        ReactDOM.render(<ModalPortalExtension onMount={(e) => { _portalExtension = e }} />, container);
    }
}();

function showModal(child) {
    _portalExtension.show(child);
}

export { showModal };
export default Modal;