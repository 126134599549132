import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

//Pages
import Error from './pages/Error';
import Main from './pages/Main';

//Other
import "./helpers/extensions";

//Styles
import "capture-form/dist/bundle-styles.css";

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/:conventionId" exact component={Main} />
          <Route path="/error/:status" exact component={Error} />
          <Redirect to="/error/404" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
