function Utilties() {

    this.getParameterByName = function (name, url) {
        if (!url) url = window.location.href;
        //name = name.replace(/[\[\]]/g, '\\$&');
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    this.getNameInitials = function (name) {
        if (!name) return "";
        var initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }

    this.promisify = function (func) {
        return new Promise((resolve, reject) => {

            //create params array;
            var params = [];
            for (var i = 1; i < arguments.length; i++) {
                params.push(arguments[i]);
            }

            //success callback
            params.push(resolve);

            //error callback
            params.push(reject);

            //run the function
            func.apply(null, params);
        });
    }

    this.generateGUID = function () {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
        );
    }
}

export default new Utilties();